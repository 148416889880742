import React, { useState, useEffect, useRef } from 'react';

import ProductsList from '../base_components/products_list';
import { SendExpressAccountRequestGet } from './send_express_account_requests';
// import CreateSendExpressAccount from './create_send_express_account';
import UpdateSendExpressAccount from './update_send_express_account';
import { ItemRendererComponent } from './send_express_account_columns_label_config';

// 列表编辑 页面

const SendExpressAccountList = ({ reloadListCount, setReloadListCount, requestParams }) => {
  const hasMore = useRef(true);
  const [hasMorestate, setHasMoreState] = useState(true);
  const [loading, setLoading] = useState(false);
  const reqParams = useRef({ page: 0 });

  const [sendExpressAccountData, setSendExpressAccountData] = useState([]);
  const [sendExpressAccountRequestGetCount, setSendExpressAccountRequestGetCount] = useState(0);

  // const [createSendExpressAccountVisible, setCreateSendExpressAccountVisible] = useState(false);
  const [updateSendExpressAccountVisible, setUpdateSendExpressAccountVisible] = useState(false);
  const [selectedObj, setSelectedObj] = useState({});

  useEffect(() => {
    // 加载、更新或创建数据后 重新读取列表
    reqParams.current = { ...requestParams };
    reloadListsHandle();
  }, [reloadListCount]);

  useEffect(() => {
    // useState才可以读取更多
    setHasMoreState(hasMore.current);
  }, [hasMore.current])

  const loadMore = () => {
    // 读取更多列表
    if (hasMore.current && !loading) {
      const current_page = Number.isInteger(reqParams.current.page) ? reqParams.current.page + 1 : 1;
      reqParams.current = { ...reqParams.current, page: current_page };
      console.log(reqParams.current);
      setSendExpressAccountRequestGetCount(sendExpressAccountRequestGetCount + 1);
      setLoading(true);
    } else {
      console.log('Error state: hasMore is ', hasMore.current, ', loading is ', loading);
    }
  };

  const reloadListsHandle = () => {
    // 重新读取数据
    reqParams.current = { ...reqParams.current, page: 0 };
    // console.log(reqParams.current);
    setSendExpressAccountData([]);
    // setHasMore(true);
    hasMore.current = true;
    setLoading(false);
    setTimeout(() => {
      loadMore();
      console.log('end loadMore...');
    }, 100);
  };

  const judgeHasMore = resp => {
    if (Object.keys(resp).includes('next')
      && typeof resp.next === 'string'
      && resp.next.length > 5) {
      return true;
    }
    return false;
  };

  const sendExpressAccountGetResponse = resp => {
    console.log('resp process...');
    setLoading(false);
    if (Object.keys(resp).includes('code')
      && resp.code === 1000
      && Object.keys(resp).includes('results')) {
      setSendExpressAccountData(sendExpressAccountData.concat(resp.results));
      // setHasMore(judgeHasMore(resp));   // 判断是否还有更多
      hasMore.current = judgeHasMore(resp);
    } else {
      console.log('Error: get data...');
    }
  };

  const itemDetailClick = (index) => {
    // 打开详情编辑窗口
    // console.log(sendExpressAccountData[index]);
    let selObj = { ...sendExpressAccountData[index] };
    console.log(selObj);
    // 日期转 moment 时间
    // selObj.end_time = moment(dateFormat(selObj.end_time), 'YYYY-MM-DD HH:mm:ss');
    setSelectedObj(selObj);
    setUpdateSendExpressAccountVisible(true);
  };

  const CurrentItemRendererComponent = ({ item, index }) => {
    // 每一行渲染 的组件
    return (
      <ItemRendererComponent item={item} index={index} itemDetailClick={itemDetailClick} />
    )
  }

  return (
    <div>
      <SendExpressAccountRequestGet requestCount={sendExpressAccountRequestGetCount}
        reqParams={reqParams.current}
        responseDataHandle={sendExpressAccountGetResponse}></SendExpressAccountRequestGet>
      <ProductsList
        productsData={sendExpressAccountData}
        loadMore={loadMore}
        hasMore={hasMorestate}
        ItemRendererComponent={CurrentItemRendererComponent}></ProductsList>

      <UpdateSendExpressAccount
        visible={updateSendExpressAccountVisible}
        setVisible={setUpdateSendExpressAccountVisible}
        oldObj={selectedObj}
        reloadListCount={reloadListCount}
        setReloadListCount={setReloadListCount}
      ></UpdateSendExpressAccount>
    </div>
  );
}

SendExpressAccountList.defaultProps = {
  reloadListCount: 1,
  setReloadListCount: (addedNum) => { console.log(addedNum); },
  requestParams: { page: 0 }
}

export default SendExpressAccountList;
