import React from 'react';

import AxiosRequest from '../apis';
import { apiLists } from './send_express_account_columns_label_config';

// SendExpressAccount 的request请求

const SendExpressAccountRequestGet = ({ requestCount, reqParams, responseDataHandle }) => {
  // SendExpressAccount Get请求
  const api = apiLists[0];
  const method = 'get';
  const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
SendExpressAccountRequestGet.defaultProps = {
  requestCount: 0,
  reqParams: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
};

const SendExpressAccountRequestPost = ({ requestCount, reqObject, responseDataHandle }) => {
  // SendExpressAccount post请求
  const api = [].concat(apiLists)[0];
  const method = 'post';
  const reqParams = {};
  // const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
SendExpressAccountRequestPost.defaultProps = {
  requestCount: 0,
  reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
}

const SendExpressAccountRequestPut = ({ requestCount, reqObject, responseDataHandle, id }) => {
  // SendExpressAccount put请求
  const api = [].concat(apiLists)[0] + `/${id}`;
  const method = 'put';
  const reqParams = {};
  // const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
SendExpressAccountRequestPut.defaultProps = {
  requestCount: 0,
  reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
  id: 0
}

const SendExpressAccountRequestDelete = ({ requestCount, responseDataHandle, id }) => {
  // SendExpressAccount delete请求
  const api = [].concat(apiLists)[0] + `/${id}`;
  const method = 'delete';
  const reqParams = {};
  const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
SendExpressAccountRequestDelete.defaultProps = {
  requestCount: 0,
  responseDataHandle: (respObj) => { console.log(respObj); },
  id: 0
}

export {
  SendExpressAccountRequestGet,
  SendExpressAccountRequestPost,
  SendExpressAccountRequestPut,
  SendExpressAccountRequestDelete
};
