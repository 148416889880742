import React from "react";

import SiteLayout from '../components/site_layout';
import SEO from "../components/seo";

import SendExpressAccountComponent from '../components/send_express_account';

const SendExpressAccountPage = () => (
  <SiteLayout>
    <SEO title="ExpressAccount" />
    <SendExpressAccountComponent></SendExpressAccountComponent>
  </SiteLayout>
)

export default SendExpressAccountPage;
