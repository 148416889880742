import React, { useState, useEffect } from 'react';
// import { DatePicker } from 'antd';
import { CustomSelect } from '../base_components/select_component';
import ListsItem from '../base_components/list_item';
import { expressCompanyRequestGet } from './custom_select_requests';

// SendExpressAccount 列 配置

// ~~~~~~ 表单列, 需添加 ~~~~~~
const formKeys = ["id", "express_company_code", "account_code", "handle_staff_id", "add_time", "update_time", "explains"];

// ~~~~~~ 表单不能编辑列, 根据实际添加 ~~~~~~
const formDisabledKeys = ["id", "handle_staff_id", "add_time", "update_time"];

// ~~~~~~ 表单不显示列, 根据实际添加 ~~~~~~
const formNotShowKeys = ["id", "handle_staff_id", "add_time", "update_time"];

// ~~~~~~ 表单列名称 label, 需添加 ~~~~~~
const formKeysLabelsObj = {
  "id": "ID", "express_company_code": "快递公司", "account_code": "发货账号",
  "handle_staff_id": "编辑人员", "add_time": "新建时间", "update_time": "更新时间", "explains": "说明"
};

//  ~~~~~~ 选择框, 根据实际添加 ~~~~~~
const SelectExpressCompany = ({ value = '', onChange = e => console.log(e) }) => {
  const [selectOptions, setSelectOptions] = useState([]);
  useEffect(() => {
    expressCompanyRequestGet().then(respObj => {
        const resList = respObj.results;
        let updateOptions = [];
        for (let index = 0; index < resList.length; index++) {
          const element = resList[index];
          updateOptions.push({
            value: element.express_company_code,
            label: element.express_company_name
          })
        }
        setSelectOptions(updateOptions);
      }).catch(error => {
        console.log(error);
        setSelectOptions([]);
      });
  }, []);
  return (<CustomSelect options={selectOptions} value={value} onChange={onChange}></CustomSelect>)
};
// const SelectEndTime = (<DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />);
// const SelectBoolean = (<CustomSelect options={[{ value: 0, label: '否' }, { value: 1, label: '是' }]} />);
const formKeySelectComponentsObj = {
  'express_company_code': <SelectExpressCompany />,
  // exampleColumn: SelectBoolean,
};

//  ~~~~~~ 表单是图片列, 根据实际添加 ~~~~~~
const formKeysIsImages = [];

//  ~~~~~~ 表单get 请求条件key, 根据实际添加 ~~~~~~
const formGetRequestParamsKeys = [];

//  ~~~~~~ 表单初始化值, 根据实际添加 ~~~~~~
const formInitialObj = {"id": 0, "express_company_code": '', "account_code": '', "handle_staff_id": 0, "add_time": "", "update_time": "", "explains": ""}

// ~~~~~~ 图片列名称, 根据实际修改 ~~~~~~
const imageKeyNames = [];

// ~~~~~~ 图片总数量, 默认设置为1, 根据实际修改 ~~~~~~
const maxImagesNum = 0;

// ~~~~~~ api请求url apiLists, 根据实际修改 ~~~~~~
const apiLists = ['kdniao-admin/v1/expressaccountinfo', ];

// 修改 ~~~~~~~ item keys ~~~~~~;
const ItemRendererComponent = ({ item, index, itemDetailClick }) => {
  // 必须 每一行渲染 的组件, 修改 ~~~~~~~ item keys ~~~~~~;
  console.log(item);
  return (<div>
    <ListsItem
      listsIndex={index}
      productTitle={item.express_company_code}
      productEffects={`发货账号: ${item.account_code}`}
      salePrice={0}
      clickDetailHandle={itemDetailClick}
    ></ListsItem>
  </div>);
};

export {
  formKeys,
  formDisabledKeys,
  formNotShowKeys,
  formKeysLabelsObj,
  formKeySelectComponentsObj,
  formKeysIsImages,
  formGetRequestParamsKeys,
  formInitialObj,
  imageKeyNames,
  maxImagesNum,
  apiLists,
  ItemRendererComponent
};
