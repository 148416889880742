import React, { useState, useRef } from 'react';
import { Button, Row, Col, Space, Tooltip } from 'antd';
import { ReloadOutlined, SearchOutlined, LeftOutlined } from '@ant-design/icons';
import { Link } from 'gatsby';

import CreateSendExpressAccount from './create_send_express_account';
import SendExpressAccountQueryDrawerForm from './send_express_account_query_form';
import SendExpressAccountList from './send_express_account_list';
import SendExpressAccountQueryParams from './send_express_account_query_params_display';
// SendExpressAccount 入口组件

const SendExpressAccountComponent = () => {
  const reqParams = useRef({ page: 0 });

  const [reloadListCount, setReloadListCount] = useState(1);

  const [drawerVisible, setDrawerVisible] = useState(false);

  const [createSendExpressAccountVisible, setCreateSendExpressAccountVisible] = useState(false);

  const queryHandle = (currentRequestParams) => {
    // 查询操作
    reqParams.current = { ...currentRequestParams };
    // 触发重新读取数据
    setReloadListCount(reloadListCount + 1)
    console.log('search...');
  };

  const initReloadList = () => {
    // 刷新列表, 删除所有查询条件
    reqParams.current = { page: 0 };
    setReloadListCount(reloadListCount + 1);
  }

  return (
    <div>
      <Row>
        <Col span={14}>
          <Space>
            <Tooltip title="返回">
              <Link to="/logistic"><LeftOutlined /></Link>
            </Tooltip>
            <h4>快递账号</h4>
            <Tooltip title="刷新">
              <Button onClick={initReloadList} shape="circle" icon={<ReloadOutlined />}></Button>
            </Tooltip>
            <Tooltip title="搜索">
              <Button onClick={() => setDrawerVisible(true)} shape="circle" icon={<SearchOutlined />}></Button>
            </Tooltip>
          </Space>
        </Col>
        <Col span={10}>
          <div style={{ width: '100%', height: 'auto' }}>
            <Button style={{ float: 'right' }} type="link" onClick={() => setCreateSendExpressAccountVisible(true)}>新建</Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}><SendExpressAccountQueryParams paramsObj={reqParams.current}></SendExpressAccountQueryParams></Col>
      </Row>
      <SendExpressAccountQueryDrawerForm
        visible={drawerVisible}
        setVisible={setDrawerVisible}
        queryHandle={queryHandle}
      ></SendExpressAccountQueryDrawerForm>

      <SendExpressAccountList
        reloadListCount={reloadListCount}
        setReloadListCount={setReloadListCount}
        requestParams={reqParams.current}
      ></SendExpressAccountList>

      <CreateSendExpressAccount
        visible={createSendExpressAccountVisible}
        setVisible={setCreateSendExpressAccountVisible}
        reloadListCount={reloadListCount}
        setReloadListCount={setReloadListCount}
      ></CreateSendExpressAccount>
    </div>
  )
};

export default SendExpressAccountComponent;
